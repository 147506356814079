import {
  LIST_CITIES_BYQUICKSEARCH,
  LIST_CITIES_BYQUICKSEARCH_ISFETCHING
} from '../actions/cities/types';

const initialState = {
  cities: undefined,
  quickSearch: {
    isFetching: false,
    cities: undefined,
    searchQuery: undefined
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_CITIES_BYQUICKSEARCH_ISFETCHING:
      return {
        ...state,
        quickSearch: {
          ...state.quickSearch,
          isFetching: !state.quickSearch.isFetching
        }
      };
    case LIST_CITIES_BYQUICKSEARCH:
      return {
        ...state,
        quickSearch: {
          isFetching: undefined,
          cities: action.cities,
          searchQuery: action.searchQuery
        }
      };
    default:
      return state;
  }
}
