import {
  LIST_USERS_BYSEARCH,
  LIST_ALL_USERS,
  OPEN_USERS_SEARCH,
  CLOSE_USERS_SEARCH
} from '../actions/users/types';
import appConfig from '../../config/app-config';

const initialState = {
  users: undefined,
  searchOpen: false,
  searchTable: {
    searchQuery: undefined,
    users: undefined,
    paginate: appConfig.defaultPaginate
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_ALL_USERS:
      return {
        ...state,
        searchTable: {
          searchQuery: undefined,
          users: action.users,
          paginate: action.paginate || {
            page: 0,
            rowsPerPage: 10,
            rowsPerPageOptions: [10],
            count: action.users ? action.users.length : 0
          }
        }
      };
    case LIST_USERS_BYSEARCH:
      return {
        ...state,
        searchOpen: action.users && action.users.length ? false : true,
        searchTable: {
          searchQuery: action.searchQuery,
          users: action.users,
          paginate: action.paginate
        }
      };
    case OPEN_USERS_SEARCH:
      return {
        ...state,
        searchOpen: true
      };
    case CLOSE_USERS_SEARCH:
      return {
        ...state,
        searchOpen: false
      };
    default:
      return state;
  }
}
