import {
  LIST_ADDRESSES_BYQUICKSEARCH,
  LIST_ADDRESSES_BYQUICKSEARCH_ISFETCHING,
  LIST_ADDRESSES_BYSEARCH,
  LIST_LATEST_ADDRESSES,
  SET_ADDRESSDETAIL,
  CLEAR_ADDRESSDETAIL,
  SET_NEARADDRESSES,
  OPEN_ADDRESS_SEARCH,
  CLOSE_ADDRESS_SEARCH,
  ADD_ADDRESS
} from '../actions/addresses/types';
import appConfig from '../../config/app-config';

const initialState = {
  addresses: undefined,
  addressDetail: {},
  changed: false,
  nearAddresses: undefined,
  searchOpen: false,
  quickSearch: {
    isFetching: false,
    addresses: undefined,
    searchQuery: undefined
  },
  searchTable: {
    searchQuery: undefined,
    filter: undefined,
    addresses: undefined,
    paginate: appConfig.defaultPaginate
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_ADDRESSES_BYQUICKSEARCH_ISFETCHING:
      return {
        ...state,
        quickSearch: {
          ...state.quickSearch,
          isFetching: !state.quickSearch.isFetching
        }
      };
    case LIST_ADDRESSES_BYQUICKSEARCH:
      return {
        ...state,
        quickSearch: {
          isFetching: undefined,
          addresses: action.addresses,
          searchQuery: action.searchQuery
        }
      };
    case LIST_LATEST_ADDRESSES:
      return {
        ...state,
        searchTable: {
          searchQuery: undefined,
          filter: action.type,
          addresses: action.addresses,
          paginate: action.paginate || {
            page: 0,
            rowsPerPage: 10,
            rowsPerPageOptions: [10],
            count: action.addresses ? action.addresses.length : 0
          }
        }
      };
    case LIST_ADDRESSES_BYSEARCH:
      return {
        ...state,
        searchOpen: action.addresses && action.addresses.length ? false : true,
        searchTable: {
          searchQuery: action.searchQuery,
          filter: undefined,
          addresses: action.addresses,
          paginate: action.paginate
        }
      };
    case SET_ADDRESSDETAIL:
      return {
        ...state,
        addressMaster: action.changed
          ? state.addressMaster
          : action.addressDetail,
        addressDetail: action.addressDetail,
        changed: action.changed || false,
        nearAddresses: undefined
      };
    case CLEAR_ADDRESSDETAIL:
      return {
        ...state,
        addressDetail: {},
        addressMaster: undefined,
        changed: false,
        nearAddresses: undefined
      };
    case SET_NEARADDRESSES:
      return {
        ...state,
        nearAddresses: action.nearAddresses
      };
    case OPEN_ADDRESS_SEARCH:
      return {
        ...state,
        searchOpen: true
      };
    case CLOSE_ADDRESS_SEARCH:
      return {
        ...state,
        searchOpen: false
      };
    case ADD_ADDRESS:
      return {
        ...state,
        searchTable: {
          searchQuery: action.searchQuery,
          addresses: undefined,
          paginate: action.paginate
        }
      };
    default:
      return state;
  }
}
