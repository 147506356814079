import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import withStyle from '@material-ui/core/styles/withStyles';
import styles from './Loader.styles';

const Loader = props => {
  const { isFetching, classes } = props;
  if (isFetching) {
    return (
      <Grid container className={classes.modal}>
        <div className={classes.loadingbox}>
          <div className={classes.spinner} />
        </div>
      </Grid>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => {
  return { isFetching: state.app.isFetching };
};

export default connect(
  mapStateToProps,
  null
)(withStyle(styles)(Loader));
