import {
  LIST_APPROVERS,
  LIST_APPROVERS_FORSEARCH
} from '../actions/approvers/types';

const initialState = {
  approvers: undefined,
  approversForSearch: undefined
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_APPROVERS:
      return { ...state, approvers: action.approvers };
    case LIST_APPROVERS_FORSEARCH:
      return { ...state, approversForSearch: action.approvers };
    default:
      return state;
  }
}
