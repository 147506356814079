export const LIST_LATEST_ORDERS = 'LIST_LATEST_ORDERS';
export const LIST_ORDERS_TODAY = 'LIST_ORDERS_TODAY';
export const LIST_ORDERS_TOMORROW = 'LIST_ORDERS_TOMORROW';
export const LIST_ORDERS_YESTERDAY = 'LIST_ORDERS_YESTERDAY';
export const LIST_ORDERS_INVOICEREADY = 'LIST_ORDERS_INVOICEREADY';
export const LIST_ORDERS_BYSEARCH = 'LIST_ORDERS_BY_SEARCH';
export const ORDER_SEARCHFORMDATA_INITIALIZE =
  'ORDER_SEARCHFORMDATA_INITIALIZE';
export const ORDER_SEARCHFORMDATA_INITIALIZED =
  'ORDER_SEARCHFORMDATA_INITIALIZED';
export const ADD_ORDER_TO_CART = 'ADD_ORDER_TO_CART';
export const REMOVE_ORDER_FROM_CART = 'REMOVE_ORDER_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const SET_ORDERDETAIL = 'SET_ORDERDETAIL';
export const SET_ROUTEDETAIL = 'SET_ROUTEDETAIL';
export const ADD_ROUTEDETAIL = 'ADD_ROUTEDETAIL';
export const EDIT_ROUTEDETAIL = 'EDIT_ROUTEDETAIL';
export const DELETE_ROUTEDETAIL = 'DELETE_ROUTEDETAIL';
export const CANCEL_ROUTEDETAIL = 'CANCEL_ROUTEDETAIL';
export const UPDATE_ORDERDETAIL = 'UPDATE_ORDERDETAIL';
export const COPY_PREVIOUS_ORDER = 'COPY_PREVIOUS_ORDER';
export const CLEAR_PREVIOUS_ORDER = 'CLEAR_PREVIOUS_ORDER';
export const CLEAR_ORDERDETAIL = 'CLEAR_ORDERDETAIL';
export const START_SAVING_CART = 'START_SAVING_CART';
export const SAVING_CART = 'SAVING_CART';
export const END_SAVING_CART = 'END_SAVING_CART';
export const ADD_ORDER = 'ADD_ORDER';
export const OPEN_ORDER_SEARCH = 'OPEN_ORDER_SEARCH';
export const CLOSE_ORDER_SEARCH = 'CLOSE_ORDER_SEARCH';
export const LIST_APPROVAL_ORDERS = 'LIST_APPROVAL_ORDERS';
export const LOAD_CART = 'LOAD_CART';
