import {
  LIST_LATEST_ORDERS,
  LIST_ORDERS_BYSEARCH,
  ADD_ORDER_TO_CART,
  REMOVE_ORDER_FROM_CART,
  CLEAR_CART,
  SET_ORDERDETAIL,
  SET_ROUTEDETAIL,
  EDIT_ROUTEDETAIL,
  ADD_ROUTEDETAIL,
  CANCEL_ROUTEDETAIL,
  COPY_PREVIOUS_ORDER,
  CLEAR_PREVIOUS_ORDER,
  LIST_ORDERS_TOMORROW,
  LIST_ORDERS_INVOICEREADY,
  LIST_ORDERS_TODAY,
  CLEAR_ORDERDETAIL,
  START_SAVING_CART,
  SAVING_CART,
  END_SAVING_CART,
  ADD_ORDER,
  OPEN_ORDER_SEARCH,
  CLOSE_ORDER_SEARCH,
  LIST_ORDERS_YESTERDAY,
  LIST_APPROVAL_ORDERS,
  LOAD_CART
} from '../actions/orders/types';
import appConfig from '../../config/app-config';
import {
  getCart,
  setCart,
  clearNewOrder,
  setNewOrder,
  clearCart
} from '../../localstorage/orders';

const initialState = {
  cart: getCart() || [],
  savingCart: false,
  editingRoute: false,
  routeDetail: {},
  changed: false,
  orderDetail: {},
  previousOrder: null,
  orderMaster: null,
  searchOpen: false,
  approvalOrders: undefined,
  searchTable: {
    searchQuery: undefined,
    filter: undefined,
    orders: undefined,
    paginate: appConfig.defaultPaginate
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_LATEST_ORDERS:
    case LIST_ORDERS_YESTERDAY:
    case LIST_ORDERS_TODAY:
    case LIST_ORDERS_TOMORROW:
    case LIST_ORDERS_INVOICEREADY:
      return {
        ...state,
        searchTable: {
          searchQuery: undefined,
          filter: action.type,
          orders: action.orders,
          paginate: action.paginate || {
            page: 0,
            rowsPerPage: 10,
            rowsPerPageOptions: [10],
            count: action.orders ? action.orders.length : 0
          }
        }
      };
    case LIST_ORDERS_BYSEARCH:
      return {
        ...state,
        searchOpen: action.orders && action.orders.length ? false : true,
        searchTable: {
          searchQuery: action.searchQuery,
          filter: undefined,
          orders: action.orders,
          paginate: action.paginate
        }
      };
    case LOAD_CART:
      return {
        ...state,
        cart: getCart() || []
      };
    case ADD_ORDER_TO_CART:
      let order = { ...action.order };
      let cart = [];
      if (!order.cartid) {
        order.cartid = Math.random()
          .toString(36)
          .substr(2, 9);
        cart = state.cart.concat([order]);
      } else {
        state.cart.forEach(cartItem => {
          if (cartItem.cartid === order.cartid) {
            cart = cart.concat([order]);
          } else {
            cart = cart.concat([cartItem]);
          }
        });
        if (cart.length === 0) {
          cart.push([order]);
        }
      }
      clearNewOrder();
      setCart(cart);
      return {
        ...state,
        previousOrder: { ...action.order },
        orderMaster: null,
        changed: false,
        orderDetail: {},
        routeDetail: {},
        cart: cart
      };
    case REMOVE_ORDER_FROM_CART:
      let newCart = [];
      state.cart.forEach(cartItem => {
        if (cartItem.cartid !== action.order.cartid) {
          newCart.push(cartItem);
        }
      });
      setCart(newCart);
      return {
        ...state,
        cart: newCart
      };
    case CLEAR_CART:
      return {
        ...state,
        cart: []
      };
    case START_SAVING_CART:
      return {
        ...state,
        savingCart: true,
        savingCartIndex: 0
      };
    case SAVING_CART:
      const saveCart = [].concat(state.cart);
      saveCart.splice(0, 1);
      setCart(saveCart);
      return {
        ...state,
        cart: saveCart
      };
    case END_SAVING_CART:
      if (action.complete) {
        clearCart();
      }
      return {
        ...state,
        cart: action.complete ? [] : state.cart,
        savingCart: false,
        orderMaster: null,
        searchTable: {
          searchQuery: undefined,
          filter: undefined,
          orders: undefined,
          paginate: appConfig.defaultPaginate
        }
      };
    case SET_ORDERDETAIL:
      if (action.changed) {
        setNewOrder(action.orderDetail);
      }
      return {
        ...state,
        previousOrder: null,
        orderDetail: action.orderDetail,
        routeDetail: {},
        orderMaster:
          !action.changed && !state.orderMaster && action.orderDetail._id
            ? JSON.parse(JSON.stringify(action.orderDetail))
            : state.orderMaster,
        changed: action.changed || false,
        editingRoute: false
      };
    case CLEAR_ORDERDETAIL:
      clearNewOrder();
      return {
        ...state,
        orderDetail: {},
        orderMaster: {},
        editingRoute: false,
        changed: false
      };
    case ADD_ROUTEDETAIL:
      setNewOrder(action.orderDetail);
      return {
        ...state,
        orderDetail: action.orderDetail,
        routeDetail: {},
        changed: true,
        editingRoute: false
      };
    case SET_ROUTEDETAIL:
      return {
        ...state,
        routeDetail: action.routeDetail
      };
    case EDIT_ROUTEDETAIL:
      let { places } = state.orderDetail.route;
      let editPlace;
      places.forEach((place, p) => {
        if (p !== action.index) {
          delete place.isEdit;
        } else {
          place.isEdit = true;
          editPlace = { ...place };
        }
      });
      return {
        ...state,
        routeDetail: editPlace,
        orderDetail: {
          ...state.orderDetail,
          route: { ...state.orderDetail.route, places: places }
        },
        editingRoute: true
      };
    case CANCEL_ROUTEDETAIL:
      const { places: rPlaces } = state.orderDetail.route;
      let newPlaces = [];
      rPlaces.forEach((place, p) => {
        delete place.isEdit;
        newPlaces = newPlaces.concat([place]);
      });
      return {
        ...state,
        routeDetail: {},
        orderDetail: {
          ...state.orderDetail,
          route: { ...state.orderDetail.route, places: newPlaces }
        },
        editingRoute: false
      };
    case COPY_PREVIOUS_ORDER:
      let { previousOrder } = state;
      delete previousOrder.fullDate;
      delete previousOrder.execDate;
      delete previousOrder.cartid;
      return {
        ...state,
        previousOrder: null,
        orderDetail: JSON.parse(JSON.stringify(previousOrder))
      };
    case CLEAR_PREVIOUS_ORDER:
      return {
        ...state,
        previousOrder: null
      };
    case ADD_ORDER:
      return {
        ...state,
        previousOrder: undefined,
        searchTable: {
          searchQuery: undefined,
          filter: undefined,
          orders: undefined,
          paginate: appConfig.defaultPaginate,
          orderMaster: null
        }
      };
    case OPEN_ORDER_SEARCH:
      return {
        ...state,
        searchOpen: true
      };
    case CLOSE_ORDER_SEARCH:
      return {
        ...state,
        searchOpen: false
      };
    case LIST_APPROVAL_ORDERS:
      return {
        ...state,
        approvalOrders: action.orders || undefined
      };
    default:
      return state;
  }
}
