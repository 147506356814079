import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import styles from './App.styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Loader from './loader/Loader';
import { changeLocale, loadLocale } from '../redux/actions/app';
import { updateViewport } from '../redux/actions/layout';
import { getAppVersion, setAppVersion } from '../localstorage/app';
import appConfig from '../config/app-config';
import { signOutUser } from '../redux/actions/auth';
import SwitchLocaleModal from './views/partials/switchLocale/SwitchLocaleModal';

const Main = lazy(() => import('./Main'));

class App extends Component {
  componentDidMount = () => {
    const appVersion = getAppVersion();
    if (!appVersion || appVersion !== appConfig.appVersion) {
      setAppVersion(appConfig.appVersion);
      if (this.props.user) {
        this.props.doSignOutUSer().then(() => {
          this.props.history.push('/');
        });
      }
    }
    window.addEventListener('resize', () => {
      this.props.doUpdateViewport();
    });
    if (!this.props.currentLocale) {
      this.props.doLoadLocale();
    }
  };

  render() {
    const { classes, currentLocale } = this.props;
    return (
      <div className={classes.app}>
        {!currentLocale && (
          <SwitchLocaleModal
            show={true}
            locales={[
              { value: 'Nederlands', i18n: 'nl_NL' },
              { value: 'English', i18n: 'en' },
            ]}
            change={this.props.doChangeLocale}
          />
        )}
        {currentLocale && (
          <Suspense fallback={<Loader />}>
            <Main />
          </Suspense>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentLocale: state.app.currentLocale,
    user: state.auth.user,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    doChangeLocale: (i18n) => {
      dispatch(changeLocale(i18n));
    },
    doSignOutUSer: () => {
      return dispatch(signOutUser());
    },
    doLoadLocale: () => {
      dispatch(loadLocale());
    },
    doUpdateViewport: () => {
      dispatch(updateViewport());
    },
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(App))
);
