import VatCode from './VatCode';
import { parseArrayOfSalutations } from './Salutation';

class OrderType {
  _id;
  name;
  translation;
  vatCode;
  salutations;
  inConnect;

  /**
   *
   * @param {String} _id
   * @param {Boolean} active
   * @param {String} name
   * @param {String} translation
   * @param {VatCode} vatCode
   * @param {Array} salutations
   * @param {Boolean} inConnect
   */
  constructor(_id, active, name, translation, vatCode, salutations, inConnect) {
    this._id = _id || null;
    this.active = active || true;
    if (!(typeof name === 'string')) {
      throw new Error('OrderType requires name property of type string');
    }
    this.name = name;
    if (!(typeof translation === 'string')) {
      throw new Error('OrderType requires translation property of type string');
    }
    this.translation = translation;
    if (!(vatCode instanceof VatCode)) {
      try {
        this.vatCode = new VatCode(
          vatCode._id,
          vatCode.code,
          vatCode.percentage,
          vatCode.orderType
        );
      } catch (error) {
        throw error;
      }
    } else {
      this.vatCode = vatCode;
    }

    if (!Array.isArray(salutations)) {
      throw new Error('salutations property should be of type Array');
    }
    try {
      this.salutations = parseArrayOfSalutations(salutations);
    } catch (error) {
      throw error;
    }

    this.inConnect = inConnect || false;
  }
}

export default OrderType;

export const parseArrayOfOrderTypes = types => {
  if (!Array.isArray(types)) {
    throw new Error('types property should be of type Array');
  }
  var res = [];
  types &&
    types.length &&
    types.forEach(type => {
      try {
        if (!(type instanceof OrderType)) {
          const orderType = new OrderType(
            type._id,
            type.active,
            type.name,
            type.translation,
            type.vatCode,
            type.salutations,
            type.inConnect
          );
          res.push(orderType);
        } else {
          res.push(type);
        }
      } catch (error) {
        throw error;
      }
    });
  return res;
};

export const getSalutationsForTypes = types => {
  if (!Array.isArray(types)) {
    throw new Error('types property should be of type Array');
  }
  var res = [];
  types &&
    types.length &&
    types.forEach(type => {
      try {
        if (!(type instanceof OrderType)) {
          throw new Error(
            'Expected an Array<OrderType> for method getSalutationsForTypes'
          );
        } else {
          res = res.concat(type.salutations);
        }
      } catch (error) {
        throw error;
      }
    });
  res.sort((a, b) => {
    return String(a.name).toLowerCase() < String(b.name).toLowerCase() ? -1 : 1;
  });
  return res;
};
