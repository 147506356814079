import { clear as clearOrderStore } from './orders';
import { clear as clearAuthStore } from './auth';

export function clear() {
  clearOrderStore();
  clearAuthStore();
}

export function clearSelectedStores(stores) {
  stores &&
    stores.length &&
    stores.forEach((store) => {
      switch (store) {
        case 'orders':
          clearOrderStore();
          break;
        case 'auth':
          clearAuthStore();
          break;
        default:
          break;
      }
    });
}
