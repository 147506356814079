class User {
  _id;
  firstName;
  lastName;
  email;
  username;
  fullName;
  customergroup;
  assigner;
  fixedApproval;
  fixedApprovers;
  language;
  invoiceApproval;
  superUser;
  emailcc;
  emailccAddresses;
  linkedUsers;
  showUsers;
  enableTrucks;
  changesAllowed;

  constructor(
    _id = null,
    firstName,
    lastName,
    email,
    username,
    fullName,
    customegroup,
    assigner,
    fixedApproval,
    fixedApprovers,
    language,
    invoiceApproval,
    superUser,
    emailcc,
    emailccAddresses,
    linkedUsers,
    showUsers,
    enableTrucks,
    changesAllowed
  ) {
    this._id = _id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.username = username;
    this.fullName = fullName;
    this.customergroup = customegroup;
    this.assigner = assigner;
    this.fixedApproval = fixedApproval;
    this.fixedApprovers = fixedApprovers;
    this.language = language;
    this.invoiceApproval = invoiceApproval;
    this.superUser = superUser;
    this.emailcc = emailcc;
    this.emailccAddresses = emailccAddresses;
    this.linkedUsers = linkedUsers;
    this.showUsers = showUsers;
    this.enableTrucks = enableTrucks;
    this.changesAllowed = changesAllowed;
  }
}

export default User;
