import { combineReducers } from "redux";
import app from "./app";
import auth from "./auth";
import layout from "./layout";
import users from "./users";
import customers from "./customers";
import orders from "./orders";
import orderTypes from "./orderTypes";
import vehicleTypes from "./vehicleTypes";
import approvers from "./approvers";
import budgets from "./budgets";
import people from "./people";
import addresses from "./addresses";
import ships from "./ships";
import vatCodes from "./vatCodes";
import cities from "./cities";
import countries from "./countries";
import feedback from "./feedback";

const appReducer = combineReducers({
  app,
  auth,
  layout,
  users,
  customers,
  orders,
  orderTypes,
  vehicleTypes,
  approvers,
  budgets,
  people,
  addresses,
  ships,
  vatCodes,
  cities,
  countries,
  feedback,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
    const { app, layout } = state;
    state = { app, layout };
  }
  return appReducer(state, action);
};

export default rootReducer;
