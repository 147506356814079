import {
  LIST_ORDERTYPES,
  LIST_ORDERTYPES_FORSEARCH
} from '../actions/orderTypes/types';
import { parseArrayOfOrderTypes } from '../../models/OrderType';

const initialState = {
  orderTypes: undefined,
  orderTypesForSearch: undefined
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_ORDERTYPES:
      return {
        ...state,
        orderTypes: parseArrayOfOrderTypes(action.orderTypes)
      };
    case LIST_ORDERTYPES_FORSEARCH:
      return {
        ...state,
        orderTypesForSearch: action.orderTypes
      };
    default:
      return state;
  }
}
