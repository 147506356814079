import {
  LIST_PEOPLE_BYQUICKSEARCH,
  LIST_PEOPLE_BYQUICKSEARCH_ISFETCHING,
  LIST_PEOPLE_BYSEARCH,
  LIST_LATEST_PEOPLE,
  SET_PEOPLEDETAIL,
  CLEAR_PEOPLEDETAIL,
  OPEN_PEOPLE_SEARCH,
  CLOSE_PEOPLE_SEARCH,
  ADD_PEOPLE
} from '../actions/people/types';
import appConfig from '../../config/app-config';

const initialState = {
  people: undefined,
  peopleDetail: {},
  changed: false,
  searchOpen: false,
  quickSearch: {
    isFetching: false,
    people: undefined,
    searchQuery: undefined
  },
  searchTable: {
    searchQuery: undefined,
    people: undefined,
    paginate: appConfig.defaultPaginate
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_PEOPLE_BYQUICKSEARCH_ISFETCHING:
      return {
        ...state,
        quickSearch: {
          ...state.quickSearch,
          isFetching: !state.quickSearch.isFetching
        }
      };
    case LIST_PEOPLE_BYQUICKSEARCH:
      return {
        ...state,
        quickSearch: {
          isFetching: undefined,
          people: action.people,
          searchQuery: action.searchQuery
        }
      };
    case LIST_LATEST_PEOPLE:
      return {
        ...state,
        searchTable: {
          searchQuery: undefined,
          people: action.people,
          paginate: action.paginate || {
            page: 0,
            rowsPerPage: 10,
            rowsPerPageOptions: [10],
            count: action.people ? action.people.length : 0
          }
        }
      };
    case LIST_PEOPLE_BYSEARCH:
      return {
        ...state,
        searchOpen: action.people && action.people.length ? false : true,
        searchTable: {
          searchQuery: action.searchQuery,
          people: action.people,
          paginate: action.paginate
        }
      };
    case SET_PEOPLEDETAIL:
      return {
        ...state,
        peopleDetail: action.peopleDetail,
        changed: action.changed || false
      };
    case CLEAR_PEOPLEDETAIL:
      return {
        ...state,
        peopleDetail: {},
        changed: false
      };
    case OPEN_PEOPLE_SEARCH:
      return {
        ...state,
        searchOpen: true
      };
    case CLOSE_PEOPLE_SEARCH:
      return {
        ...state,
        searchOpen: false
      };
    case ADD_PEOPLE:
      return {
        ...state,
        searchTable: {
          searchQuery: action.searchQuery,
          people: undefined,
          paginate: action.paginate
        }
      };
    default:
      return state;
  }
}
