import { LIST_VATCODES } from '../actions/vatCodes/types';
import { parseArrayOfVatCodes } from '../../models/VatCode';

const initialState = {
  vatCodes: undefined
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_VATCODES:
      return {
        ...state,
        vatCodes: parseArrayOfVatCodes(action.vatCodes)
      };
    default:
      return state;
  }
}
