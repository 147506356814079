export const LIST_BUDGETS_BYQUICKSEARCH = 'LIST_BUDGETS_BYQUICKSEARCH';
export const LIST_BUDGETS_BYQUICKSEARCH_ISFETCHING =
  'LIST_BUDGETS_BYQUICKSEARCH_ISFETCHING';
export const LIST_LATEST_BUDGETS = 'LIST_LATEST_BUDGETS';
export const LIST_BUDGETS_BYSEARCH = 'LIST_BUDGETS_BYSEARCH';
export const SET_BUDGETSDETAIL = 'SET_BUDGETSDETAIL';
export const CLEAR_BUDGETSDETAIL = 'CLEAR_BUDGETSDETAIL';
export const OPEN_BUDGETS_SEARCH = 'OPEN_BUDGETS_SEARCH';
export const CLOSE_BUDGETS_SEARCH = 'CLOSE_BUDGETS_SEARCH';
export const ADD_BUDGETS = 'ADD_BUDGETS';
export const SET_EXISTING_BUDGETS = 'SET_EXISTING_BUDGETS';
