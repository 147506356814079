import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './SwitchLocaleModal.styles';
import Grid from '@material-ui/core/Grid';

/**
 * SwitchLocaleModal
 * Provides a screen overlay for language selection
 */

class SwitchLocaleModal extends Component {
  handleClick = (event, i18n) => {
    event.preventDefault();
    this.props.change(i18n);
  };

  render() {
    const { show, locales, classes } = this.props;
    if (show) {
      return (
        <Grid container className={classes.modal}>
          <main aria-label="Kies Taal" className={classes.picker}>
            {' '}
            {locales.map((loc, i) => {
              return (
                <a
                  aria-label={loc.value}
                  href={loc.i18n}
                  className={classes.option}
                  key={i}
                  onClick={event => this.handleClick(event, loc.i18n)}
                >
                  {loc.value}
                </a>
              );
            })}{' '}
          </main>
        </Grid>
      );
    } else {
      return null;
    }
  }
}

export default withStyles(styles)(SwitchLocaleModal);
