import {
  LIST_VEHICLETYPES,
  LIST_VEHICLETYPES_FORSEARCH
} from '../actions/vehicleTypes/types';

const initialState = {
  vehicleTypes: undefined,
  vehicleTypesForSearch: undefined
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_VEHICLETYPES:
      return { ...state, vehicleTypes: action.vehicleTypes };
    case LIST_VEHICLETYPES_FORSEARCH:
      return { ...state, vehicleTypesForSearch: action.vehicleTypes };
    default:
      return state;
  }
}
