import React from 'react';
import { detect } from 'detect-browser';

export default () => {
  const browser = detect();
  return (
    <div style={{ textAlign: 'center', margin: '0 auto' }}>
      <h1>We are sorry but your browser is not supported</h1>
      {(browser && (
        <p>
          Your current browser : {browser.os.toUpperCase()}{' '}
          {browser.name.toUpperCase()} {browser.version}
        </p>
      )) ||
        null}
      <p>
        Gorselé intends to keep up with the latest web and security standards to
        provide our customers with a user friendly and secure application.
        <br />
        It seems you are using a very old browser which we, and Microsoft, no
        longer support.
        <br />
        As of 2016 Microsoft stopped the support for its Internet Explorer
        browser. <br />
        Microsoft Edge was introduced to keep up with the latest standards.
        <br />
        We recommend updating your browser to at least Internet Explorer
        11.0.56, Microsoft Edge, Google Chrome, Mozilla Firefox or Opera.
        <br />
        <br />
        You can read more about Microsoft ending support on{' '}
        <a href="https://www.microsoft.com/en-us/windowsforbusiness/end-of-ie-support">
          https://www.microsoft.com/en-us/windowsforbusiness/end-of-ie-support
        </a>
      </p>
    </div>
  );
};
