import {
  LIST_CUSTOMERS,
  LIST_CUSTOMERS_FORSEARCH
} from '../actions/customers/types';

const initialState = {
  customers: undefined,
  customersForSearch: undefined
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_CUSTOMERS:
      return { ...state, customers: action.customers };
    case LIST_CUSTOMERS_FORSEARCH:
      return { ...state, customersForSearch: action.customers };
    default:
      return state;
  }
}
