import {
  LIST_BUDGETS_BYQUICKSEARCH,
  LIST_BUDGETS_BYQUICKSEARCH_ISFETCHING,
  LIST_BUDGETS_BYSEARCH,
  LIST_LATEST_BUDGETS,
  SET_BUDGETSDETAIL,
  CLEAR_BUDGETSDETAIL,
  OPEN_BUDGETS_SEARCH,
  CLOSE_BUDGETS_SEARCH,
  ADD_BUDGETS,
  SET_EXISTING_BUDGETS
} from '../actions/budgets/types';
import appConfig from '../../config/app-config';

const initialState = {
  budgets: undefined,
  existingBudgets: undefined,
  budgetDetail: {},
  changed: false,
  searchOpen: false,
  quickSearch: {
    isFetching: false,
    budgets: undefined,
    searchQuery: undefined
  },
  searchTable: {
    searchQuery: undefined,
    budgets: undefined,
    paginate: appConfig.defaultPaginate
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_BUDGETS_BYQUICKSEARCH_ISFETCHING:
      return {
        ...state,
        quickSearch: {
          ...state.quickSearch,
          isFetching: !state.quickSearch.isFetching
        }
      };
    case LIST_BUDGETS_BYQUICKSEARCH:
      return {
        ...state,
        quickSearch: {
          isFetching: undefined,
          budgets: action.budgets,
          searchQuery: action.searchQuery
        }
      };
    case LIST_LATEST_BUDGETS:
      return {
        ...state,
        searchTable: {
          searchQuery: undefined,
          budgets: action.budgets,
          paginate: action.paginate || {
            page: 0,
            rowsPerPage: 10,
            rowsPerPageOptions: [10],
            count: action.budgets ? action.budgets.length : 0
          }
        }
      };
    case LIST_BUDGETS_BYSEARCH:
      return {
        ...state,
        searchOpen: action.budgets && action.budgets.length ? false : true,
        searchTable: {
          searchQuery: action.searchQuery,
          budgets: action.budgets,
          paginate: action.paginate
        }
      };
    case SET_BUDGETSDETAIL:
      return {
        ...state,
        budgetDetail: action.budgetDetail,
        changed: action.changed || false,
        existingBudgets: undefined
      };
    case CLEAR_BUDGETSDETAIL:
      return {
        ...state,
        budgetDetail: {},
        changed: false,
        existingBudgets: undefined
      };
    case OPEN_BUDGETS_SEARCH:
      return {
        ...state,
        searchOpen: true
      };
    case CLOSE_BUDGETS_SEARCH:
      return {
        ...state,
        searchOpen: false
      };
    case SET_EXISTING_BUDGETS:
      return {
        ...state,
        existingBudgets: action.budgets
      };
    case ADD_BUDGETS:
      return {
        ...state,
        searchTable: {
          searchQuery: action.searchQuery,
          budgets: undefined,
          paginate: action.paginate
        }
      };
    default:
      return state;
  }
}
