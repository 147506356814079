export function setNewOrder(order) {
  localStorage.setItem('newOrder', JSON.stringify(order));
  localStorage.setItem('newOrderLastUpdate', Date.now().valueOf());
}

export function getNewOrder() {
  const order = localStorage.getItem('newOrder');
  const lastUpdate = localStorage.getItem('newOrderLastUpdate');
  const until = Date.now() - 2 * 60 * 60 * 1000; //keep for two hours in store
  if (lastUpdate && parseInt(lastUpdate) > until && order) {
    return JSON.parse(order);
  }
  clearNewOrder();
  return null;
}

export function clearNewOrder() {
  localStorage.removeItem('newOrder');
  localStorage.removeItem('newOrderLastUpdate');
}

export function setCart(cart) {
  localStorage.setItem('cart', JSON.stringify(cart));
  localStorage.setItem('cartLastUpdate', Date.now().valueOf());
}

export function getCart() {
  const cart = localStorage.getItem('cart');
  const lastUpdate = localStorage.getItem('cartLastUpdate');
  const until = Date.now() - 2 * 60 * 60 * 1000; //keep for two hours in store
  if (lastUpdate && parseInt(lastUpdate) > until && cart) {
    return JSON.parse(cart);
  }
  clearCart();
  return null;
}

export function clearCart() {
  localStorage.removeItem('cart');
  localStorage.removeItem('cartLastUpdate');
}

export function clear() {
  clearNewOrder();
  clearCart();
}
