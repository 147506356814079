export const LIST_PEOPLE_BYQUICKSEARCH = 'LIST_PEOPLE_BYQUICKSEARCH';
export const LIST_PEOPLE_BYQUICKSEARCH_ISFETCHING =
  'LIST_PEOPLE_BYQUICKSEARCH_ISFETCHING';
export const LIST_LATEST_PEOPLE = 'LIST_LATEST_PEOPLE';
export const LIST_PEOPLE_BYSEARCH = 'LIST_PEOPLE_BYSEARCH';
export const SET_PEOPLEDETAIL = 'SET_PEOPLEDETAIL';
export const CLEAR_PEOPLEDETAIL = 'CLEAR_PEOPLEDETAIL';
export const OPEN_PEOPLE_SEARCH = 'OPEN_PEOPLE_SEARCH';
export const CLOSE_PEOPLE_SEARCH = 'CLOSE_PEOPLE_SEARCH';
export const ADD_PEOPLE = 'ADD_PEOPLE';
