import { LIST_COUNTRIES } from '../actions/countries/types';

const initialState = {
  countries: undefined
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_COUNTRIES:
      return {
        ...state,
        countries: action.countries
      };
    default:
      return state;
  }
}
