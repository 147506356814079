class Salutation {
  name;
  translation;
  type;

  /**
   *
   * @param {string} name
   * @param {string} translation
   * @param {string} type
   */
  constructor(name, translation, type) {
    if (!(typeof name === 'string')) {
      throw new Error('Salutation requires name property of type string');
    }
    this.name = name;
    if (!(typeof translation === 'string')) {
      throw new Error(
        'Salutation requires translation property of type string'
      );
    }
    this.translation = translation;
    if (!(typeof type === 'string')) {
      throw new Error('Salutation requires type property of type string');
    }
    this.type = type;
  }
}

export default Salutation;

export const parseArrayOfSalutations = salutations => {
  if (!Array.isArray(salutations)) {
    throw new Error('salutations property should be of type Array');
  }
  var res = [];
  salutations &&
    salutations.length &&
    salutations.forEach(sal => {
      try {
        if (!(sal instanceof Salutation)) {
          const salutation = new Salutation(
            sal.name,
            sal.translation,
            sal.type
          );
          res.push(salutation);
        } else {
          res.push(sal);
        }
      } catch (error) {
        throw error;
      }
    });
  return res;
};
