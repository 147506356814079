class VatCode {
  _id;
  code;
  percentage;
  orderType;
  country;
  international;
  standard;
  invoiceVatText;
  countryForInvoice;
  employerTotalCode;
  employerTotalNationalNoVat;

  constructor(
    _id,
    code,
    percentage,
    orderType,
    country,
    international,
    standard,
    invoiceVatText,
    countryForInvoice,
    employerTotalCode,
    employerTotalNationalNoVat
  ) {
    this._id = _id || null;
    if (code === undefined || !(typeof code === 'number')) {
      throw new Error('VatCode requires code property of type Number');
    }
    this.code = code;
    if (percentage === undefined || !(typeof percentage === 'number')) {
      if (typeof percentage === 'string') {
        percentage = Number(percentage);
      } else {
        throw new Error('VatCode requires percentage property of type Number');
      }
    }
    this.percentage = percentage;
    if (orderType) {
      this.orderType = orderType;
    }
    if (country) {
      this.country = country;
    }
    if (international !== undefined) {
      this.international = international;
    }
    if (standard !== undefined) {
      this.standard = standard;
    }
    if (invoiceVatText) {
      this.invoiceVatText = invoiceVatText;
    }
    if (countryForInvoice) {
      this.countryForInvoice = countryForInvoice;
    }
    if (employerTotalCode !== undefined) {
      this.employerTotalCode = employerTotalCode;
    }
    if (employerTotalNationalNoVat !== undefined) {
      this.employerTotalNationalNoVat = employerTotalNationalNoVat;
    }
  }
}

export const parseArrayOfVatCodes = vatCodes => {
  if (!Array.isArray(vatCodes)) {
    throw new Error('codes property should be of type Array');
  }
  var res = [];
  vatCodes &&
    vatCodes.length &&
    vatCodes.forEach(code => {
      try {
        if (!(code instanceof VatCode)) {
          const vatCode = new VatCode(
            code._id,
            code.code,
            code.percentage,
            code.orderType,
            code.country,
            code.international,
            code.standard,
            code.invoiceVatText,
            code.countryForInvoice,
            code.employerTotalCode,
            code.employerTotalNationalNoVat
          );
          res.push(vatCode);
        } else {
          res.push(code);
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    });
  return res;
};

export default VatCode;
