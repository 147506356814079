import {
  LIST_SHIPS_BYQUICKSEARCH,
  LIST_SHIPS_BYQUICKSEARCH_ISFETCHING
} from '../actions/ships/types';

const initialState = {
  ships: undefined,
  quickSearch: {
    isFetching: false,
    ships: undefined,
    searchQuery: undefined
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_SHIPS_BYQUICKSEARCH_ISFETCHING:
      return {
        ...state,
        quickSearch: {
          ...state.quickSearch,
          isFetching: !state.quickSearch.isFetching
        }
      };
    case LIST_SHIPS_BYQUICKSEARCH:
      return {
        ...state,
        quickSearch: {
          isFetching: undefined,
          ships: action.ships,
          searchQuery: action.searchQuery
        }
      };
    default:
      return state;
  }
}
