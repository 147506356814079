import { TOGGLE_SIDEMENU, UPDATE_VIEWPORT } from '../actions/layout/types';

const getViewport = () => {
  const IW = window.innerWidth;
  return {
    xl: IW >= 1920 ? true : false,
    lg: IW >= 1280 ? true : false,
    md: IW >= 960 ? true : false,
    sm: IW >= 600 ? true : false,
    xs: IW < 600 ? true : false
  };
};

const initialState = {
  navigationOpen: false,
  viewport: getViewport()
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDEMENU:
      return { ...state, navigationOpen: !state.navigationOpen };
    case UPDATE_VIEWPORT:
      return {
        ...state,
        viewport: getViewport()
      };
    default:
      return state;
  }
}
