/**
 * Colors
 */
const primaryColor = "#1565c0";
const secondaryColor = "#009688";
const darkTextColor = "#505050";
const progressColor = "#f50057";
const lightBorderColor = "#ededed";

/**
 * Font
 */
const fontFamily = '"Helvetica", "sans-serif"';
const fontSize = "1rem";
const lineHeight = 1.7;
const fontWeight = 400;

/**
 * Layout
 */
const margin = 10;

const MuiTheme = {
  typography: {
    useNextVariants: true,
    fontFamily: ["Helvetica", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  abstracts: {
    variables: {
      colors: {
        primary: primaryColor,
        secondary: secondaryColor,
        darkText: darkTextColor,
        progress: progressColor,
        border: lightBorderColor,
      },
      font: {
        fontFamily: fontFamily,
        fontSize: fontSize,
        lineHeight: lineHeight,
        fontWeight: fontWeight,
      },
      layout: {
        defaultMargin: margin,
      },
    },
  },
};

export default MuiTheme;
