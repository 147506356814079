import { detect } from "detect-browser";

export function polyFillIE() {
  const browser = detect();
  if (browser && browser.name === "ie") {
    const version = browser.version;
    const main = version.split(".");
    if (main && parseInt(main[0]) && parseInt(main[0]) === 11) {
      return true;
    }
    return false;
  }
  return false;
}

export function isLegacy() {
  const browser = detect();
  if (browser && browser.name === "ie") {
    const version = browser.version;
    const main = version.split(".");
    if (main && parseInt(main[0]) && parseInt(main[0]) < 11) {
      return true;
    }
  }
  try {
    Promise.resolve();
    if (typeof Object.assign !== "function") {
      return true;
    }
    return false;
  } catch (e) {
    return true;
  }
}
