export default theme => ({
  modal: {
    height: 'calc(100vh)',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    background:
      'linear-gradient(110deg,rgb(76, 131, 168) 0%,rgba(114, 167, 194, 1) 60%,rgba(9, 70, 121, 1) 100%)',
    zIndex: 9999,
    alignItems: 'center',
    justifyContent: 'center'
  },
  picker: {
    backgroundColor: 'transparent',
    overflow: 'hidden'
  },
  option: {
    position: 'relative',
    color: theme.abstracts.variables.colors.darkText,
    display: 'block',
    width: '80vw',
    maxWidth: '30rem',
    padding: '2rem 2rem',
    textAlign: 'left',
    textTransform: 'uppercase',
    fontSize: '1rem',
    textDecoration: 'none',
    transition: 'all 0.6s',
    marginBottom: '1px',
    backgroundColor: 'white',
    '&:hover': {
      color: 'white',
      textDecoration: 'none',
      transform: 'translateX(2rem)',
      '&:after': {
        transform: 'scaleX(1)',
        opacity: 1
      }
    },
    '&:after': {
      content: '""',
      display: 'inline-block',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: -1,
      paddingLeft: '2rem',
      transformOrigin: '100% 0',
      transform: 'scaleX(0)',
      transition: 'all 0.4s',
      opacity: 0,
      backgroundColor: theme.abstracts.variables.colors.primary
    }
  }
});
