import {
  SET_FEEDBACKDETAIL,
  CLEAR_FEEDBACKDETAIL,
} from "../actions/feedback/types";

const initialState = {
  feedbackDetail: {},
  changed: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_FEEDBACKDETAIL:
      return {
        ...state,
        feedbackDetail: action.feedbackDetail,
        changed: true,
      };
    case CLEAR_FEEDBACKDETAIL:
      return {
        ...state,
        feedbackDetail: {},
        changed: false,
      };
    default:
      return state;
  }
}
