export const LIST_ADDRESSES_BYQUICKSEARCH = 'LIST_ADDRESSES_BYQUICKSEARCH';
export const LIST_ADDRESSES_BYQUICKSEARCH_ISFETCHING =
  'LIST_ADDRESSES_BYQUICKSEARCH_ISFETCHING';
export const LIST_ADDRESSES_BYSEARCH = 'LIST_ADDRESSES_BYSEARCH';
export const LIST_LATEST_ADDRESSES = 'LIST_LATEST_ADDRESSES';
export const SET_ADDRESSDETAIL = 'SET_ADDRESSDETAIL';
export const CLEAR_ADDRESSDETAIL = 'CLEAR_ADDRESSDETAIL';
export const SET_NEARADDRESSES = 'SET_NEARADDRESSES';
export const OPEN_ADDRESS_SEARCH = 'OPEN_ADDRESS_SEARCH';
export const CLOSE_ADDRESS_SEARCH = 'CLOSE_ADDRESS_SEARCH';
export const ADD_ADDRESS = 'ADD_ADDRESS';
